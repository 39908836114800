/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Camera } from '../models/camera';
import { Result } from '../models/result';
import { Status } from '../models/status';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getResult
   */
  static readonly GetResultPath = '/result';

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResult$Xml()` instead.
   *
   * This method doesn't expect any response body
   */
  getResult$Xml$Response(params?: {

  }): Observable<StrictHttpResponse<Result>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetResultPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Result>;
      })
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResult$Xml$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getResult$Xml(params?: {

  }): Observable<Result> {

    return this.getResult$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResult$Json()` instead.
   *
   * This method doesn't expect any response body
   */
  getResult$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Result>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetResultPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Result>;
      })
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResult$Json$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getResult$Json(params?: {

  }): Observable<Result> {

    return this.getResult$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Result>) => r.body as Result)
    );
  }

  /**
   * Path part for operation getResultByCamera
   */
  static readonly GetResultByCameraPath = '/result/{camera}';

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResultByCamera$Xml()` instead.
   *
   * This method doesn't expect any response body
   */
  getResultByCamera$Xml$Response(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<StrictHttpResponse<Camera>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetResultByCameraPath, 'get');
    if (params) {

      rb.path('camera', params.camera);

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Camera>;
      })
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResultByCamera$Xml$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getResultByCamera$Xml(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<Camera> {

    return this.getResultByCamera$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Camera>) => r.body as Camera)
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResultByCamera$Json()` instead.
   *
   * This method doesn't expect any response body
   */
  getResultByCamera$Json$Response(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<StrictHttpResponse<Camera>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetResultByCameraPath, 'get');
    if (params) {

      rb.path('camera', params.camera);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Camera>;
      })
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getResultByCamera$Json$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getResultByCamera$Json(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<Camera> {

    return this.getResultByCamera$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Camera>) => r.body as Camera)
    );
  }

  /**
   * Path part for operation getCameraPicture
   */
  static readonly GetCameraPicturePath = '/camera/{camera}/picture';

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCameraPicture()` instead.
   *
   * This method doesn't expect any response body
   */
  getCameraPicture$Response(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetCameraPicturePath, 'get');
    if (params) {

      rb.path('camera', params.camera);

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrievs the result object (cameras, status)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCameraPicture$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getCameraPicture(params: {

    /**
     * ID of camera to return
     */
    camera: string;

  }): Observable<Blob> {

    return this.getCameraPicture$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getStatus
   */
  static readonly GetStatusPath = '/status';

  /**
   * Retrievs the status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatus$Xml()` instead.
   *
   * This method doesn't expect any response body
   */
  getStatus$Xml$Response(params?: {

  }): Observable<StrictHttpResponse<Status>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetStatusPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Status>;
      })
    );
  }

  /**
   * Retrievs the status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatus$Xml$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getStatus$Xml(params?: {

  }): Observable<Status> {

    return this.getStatus$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Status>) => r.body as Status)
    );
  }

  /**
   * Retrievs the status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatus$Json()` instead.
   *
   * This method doesn't expect any response body
   */
  getStatus$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Status>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetStatusPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Status>;
      })
    );
  }

  /**
   * Retrievs the status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatus$Json$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getStatus$Json(params?: {

  }): Observable<Status> {

    return this.getStatus$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Status>) => r.body as Status)
    );
  }

  /**
   * Path part for operation getTotalResult
   */
  static readonly GetTotalResultPath = '/totalResult';

  /**
   * Retrievs the final calculated matrix
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTotalResult()` instead.
   *
   * This method doesn't expect any response body
   */
  getTotalResult$Response(params?: {

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.GetTotalResultPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Retrievs the final calculated matrix
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTotalResult$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  getTotalResult(params?: {

  }): Observable<string> {

    return this.getTotalResult$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation setCalibrationResult
   */
  static readonly SetCalibrationResultPath = '/totalResult';

  /**
   * Ok/NOk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCalibrationResult()` instead.
   *
   * This method doesn't expect any response body
   */
  setCalibrationResult$Response(params?: {

    /**
     * Calibration finished:
     *  * &#x60;FAILED&#x60; - Result failed
     *  * &#x60;PASSED&#x60; - Result passed
     */
    result?: 'FAILED' | 'PASSED';

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApiService.SetCalibrationResultPath, 'post');
    if (params) {

      rb.query('result', params.result);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Ok/NOk
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setCalibrationResult$Response()` instead.
   *
   * This method doesn't expect any response body
   */
  setCalibrationResult(params?: {

    /**
     * Calibration finished:
     *  * &#x60;FAILED&#x60; - Result failed
     *  * &#x60;PASSED&#x60; - Result passed
     */
    result?: 'FAILED' | 'PASSED';

  }): Observable<void> {

    return this.setCalibrationResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
