import { Component, OnInit } from '@angular/core';
import { LanguageItem } from '@inst-iot/bosch-angular-ui-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tda-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languages: LanguageItem[] = [
    { id: 'de', name: 'Deutsch' },
    { id: 'en', name: 'English' }
  ];

  language = 'en';

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
  }

  onLanguageChanged(newLang: string) {
    this.translate.setDefaultLang(this.language);
  }

}
