import { Component, OnInit, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

@Component({
  selector: 'tda-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent implements OnInit, OnChanges {
  @Input() value: number;
  strokeWidth = 10;
  radius = 50 - this.strokeWidth / 2;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;

  @HostBinding("attr.style")
  color: any;

  constructor() {
    this.progress(0);
    console.log("color-style: ", this.color);
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue !== changes.value.previousValue) {
      this.progress(changes.value.currentValue);
    }
  }

  private progress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
    console.log("circumference: " + this.circumference);
    console.log("progress     : " + progress);
    console.log("dashoffset   : " + this.dashoffset);

  }
}