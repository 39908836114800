import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CommunicationState, AthenaService } from "../services/athena.service";
import { distinctUntilChanged, flatMap, tap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Component({
  selector: 'tda-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContentComponent implements OnInit {

  public readonly connectionStatus_ = this.athenaService.communicationState.pipe(tap(
    (next: any) => {
      this.status = next;
    }
  ));

  status: CommunicationState = CommunicationState.DISCONNECTED
  showDebug = false;
  address: any = undefined

  constructor(private athenaService: AthenaService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.route.queryParamMap.subscribe(m => {
      this.address = m.get('ip');
      if (this.address) {
        console.log("demo service url: " + this.address)
        this.athenaService.setRootUrl(this.address);
      }
    })
    // this.router.navigate(
    //   ['.'],
    //   { relativeTo: this.route }
    // )
  }

  getErrorMsg() {
    switch (this.status) {
      case CommunicationState.CONNECTED:
        return undefined;
      case CommunicationState.ERROR:
        return "error.unknown";
      case CommunicationState.UNAUTHORIZED:
        return "error.unauthorized";
      case CommunicationState.DISCONNECTED:
      default:
        return "error.disconnected";
    }
  }

  // retryConnection() {
  //   this.athenaService.retryConnection();
  // }

}
