import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, interval, Subscription } from 'rxjs';

import { ApiService } from "../athena-api/services";
import { Result, Status } from '../athena-api/models';


export enum CommunicationState {
  DISCONNECTED,
  UNAUTHORIZED,
  CONNECTED,
  ERROR,
}


@Injectable({
  providedIn: 'root'
})
export class AthenaService {


  result = new BehaviorSubject<Result>(undefined);
  status = new BehaviorSubject<Status>(undefined);
  communicationState: BehaviorSubject<CommunicationState> = new BehaviorSubject<CommunicationState>(CommunicationState.DISCONNECTED);
  intervalSubsription: Subscription;

  CONNECTED_POLLING_TIME = 500;
  DISCONNECTED_POLLING_TIME = 10000;

  constructor(private api: ApiService) {
    this.startPolling(this.CONNECTED_POLLING_TIME);
  }


  /**
   * Ends the interval mainly in case of error 
   */
  private stopPolling() {
    if (this.intervalSubsription) {
      this.intervalSubsription.unsubscribe();
      this.intervalSubsription = undefined;
    }

  }

  /**
   * Retries the connection to athena service
   */
  private retryConnection() {
    this.startPolling(this.CONNECTED_POLLING_TIME);
  }

  /**
   * Starts the polling interval to retreive information from athena server
   * @param cycle 
   */
  private startPolling(cycle: number) {
    // cleanup before...
    this.stopPolling();

    this.intervalSubsription = interval(cycle).subscribe(num => {
      // console.log('interval: ' + num);
      this.readStatus();
      //this.readResult();
    });
  }


  /**
   * Called by interceptors to handle error.
   * @param state communication state depending on status code
   * @param code the status code for detailed error handling
   */
  handleCommunicationState(state: CommunicationState, code: number) {
    switch (state) {
      case CommunicationState.CONNECTED:
        this.startPolling(this.CONNECTED_POLLING_TIME);
        break;
      case CommunicationState.UNAUTHORIZED:
      case CommunicationState.DISCONNECTED:
      case CommunicationState.ERROR:
      default:
        this.startPolling(this.DISCONNECTED_POLLING_TIME);
        this.result.next(undefined);
        this.status.next(undefined);
        break;
    }

    this.communicationState.next(state);
  }


  /**
   * read the result from service
   */
  readResult() {
    this.api.getResult$Json().subscribe(
      res => {
        this.result.next(res);
      },
      err => {
        console.error(err);
      })
  }

  /**
   * Returns the result observable.
   */
  getResult(): Observable<Result> {
    return this.result;
  }

  /**
   * Read the current status from Athena server
   */
  readStatus() {
    this.api.getStatus$Json().subscribe(
      stat => {
        this.status.next(stat);
      });
  }

  /**
   * Returns an observable from status store.
   */
  getStatus(): Observable<Status> {
    return this.status;
  }

  setRootUrl(address: any) {
    this.api.rootUrl = address;
    console.log('root url updated to: ' + this.api.rootUrl)
  }
}
