import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RbUiComponentsModule } from '@inst-iot/bosch-angular-ui-components';
import { DebugViewComponent } from './debug-view/debug-view.component';
import { StatusComponent } from './status/status.component';
import { ContentComponent } from './content/content.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ApiModule } from './athena-api/api.module';
import { AthenaApiConfiguration } from 'src/environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';


@NgModule({
  declarations: [
    AppComponent,
    DebugViewComponent,
    StatusComponent,
    ContentComponent,
    HeaderComponent,
    FooterComponent,
    ProgressIndicatorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RbUiComponentsModule,
    HttpClientModule,
    ApiModule.forRoot(AthenaApiConfiguration),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
