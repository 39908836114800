import { Component, OnInit, OnDestroy } from '@angular/core';
import { AthenaService } from '../services/athena.service';
import { Status, StatusType } from '../athena-api/models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'tda-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  status: Observable<Status>;

  constructor(private athenaService: AthenaService) {
    this.status = athenaService.getStatus();
  }

  ngOnInit() {
  }

  updateStatusStyle(currentStatus: Status) {
    switch (currentStatus.type) {
      case StatusType.DriveIn:
        return 'status--enter';
      case StatusType.DriveOut:
        return 'status--leave';
      case StatusType.CalibrationFinished:
      case StatusType.CalibrationStart:
      case StatusType.MoveableTargetsPoseEstimation:
        return 'status--wait';
      case StatusType.RemoveMoveableTargets:
      case StatusType.PlaceMoveableTargets:
      case StatusType.LeaveArea:
        return 'status--action';
      case StatusType.Init:
      default:
        return 'status--initial';
    }
  }


  getDetailTextId(currentStatus: Status) {
    switch (currentStatus.detailsType) {
      case "TIMER": {
        switch (currentStatus.type) {
          case StatusType.LeaveArea:
            return 'status.detail.leave-area';
          case StatusType.RemoveMoveableTargets:
            return 'status.detail.remove-moveable-targets';
          case StatusType.PlaceMoveableTargets:
            return 'status.detail.place-moveable-targets';
          default:
            return undefined;
        }
      }
      case "PROGRESS": {
        return undefined;
      }
      case "INFO_ID":
      default: {
        return currentStatus.details;
      }
    }
  }

  getIconStyle(currentStatus: Status) {
    if (currentStatus.detailsType === "TIMER") {
      switch (currentStatus.type) {
        case StatusType.LeaveArea:
          return 'rb-ic rb-ic-user-run';
        case StatusType.RemoveMoveableTargets:
          return 'rb-ic rb-ic-warranty';
        case StatusType.PlaceMoveableTargets:
          return 'rb-ic rb-ic-wand';
        default:
          return undefined;
      }
    } else {
      return currentStatus.details;
    }
  }

}
