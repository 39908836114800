import { Component } from '@angular/core';
import { AthenaService } from '../services/athena.service';
import { Observable } from 'rxjs';
import { Result, Status } from '../athena-api/models';

@Component({
  selector: 'tda-debug-view',
  templateUrl: './debug-view.component.html',
  styleUrls: ['./debug-view.component.scss']
})
export class DebugViewComponent {

  showResult = false;
  showStatus = false;
  result: Observable<Result>;
  status: Observable<Status>;

  constructor(athenaService: AthenaService) {
    this.result = athenaService.getResult();
    this.status = athenaService.getStatus();
  }

  toggleShowResult() {
    this.showStatus = false;
    this.showResult = !this.showResult;
  }

  toggleShowStatus() {
    this.showResult = false;
    this.showStatus = !this.showStatus;
  }
}
