/* tslint:disable */
export enum StatusType {
  Init = 'INIT',
  TargetsPoseEstimation = 'TARGETS_POSE_ESTIMATION',
  DriveIn = 'DRIVE_IN',
  LeaveArea = 'LEAVE_AREA',
  VehiclePoseEstimation = 'VEHICLE_POSE_ESTIMATION',
  PlaceMoveableTargets = 'PLACE_MOVEABLE_TARGETS',
  MoveableTargetsPoseEstimation = 'MOVEABLE_TARGETS_POSE_ESTIMATION',
  CalibrationStart = 'CALIBRATION_START',
  CalibrationFinished = 'CALIBRATION_FINISHED',
  RemoveMoveableTargets = 'REMOVE_MOVEABLE_TARGETS',
  DriveOut = 'DRIVE_OUT'
}
